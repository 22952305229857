<template>
  <v-fade-transition>
    <kits-panel
      :kits-items="blackPins"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Black PIN
      </h3>
    </kits-panel>
  </v-fade-transition>
</template>

<script>
// import security from '@/security'

const formTitle = 'Black PIN'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: 'Black', disabled: false, to: '/black_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
    }
  },
  computed: {
    blackPins: function () {
      const menus = []
      /*
      if (security.me.dealerRank() === 'Master Dealer' || security.me.dealerRank() === 'Area Dealer' ||
        security.me.dealerId() === 'DYSD3613' || security.me.dealerId() === 'TKKR1' || security.me.dealerId() === 'D000000') {
        menus.push(
          {
            icon: 'mdi-transfer',
            path: '/black_transfer_pin',
            title: 'Transfer Black PIN',
            desc: 'Transfer Black PIN to other qualified dealers.'
          }
        )
      }
      */
      menus.push(
        {
          icon: 'mdi-transfer',
          path: '/black_transfer_pin',
          title: 'Transfer Black PIN',
          desc: 'Transfer Black PIN to other qualified dealers.',
        },
      )
      menus.push(
        {
          icon: 'mdi-file-document-box',
          path: '/black_pin_transactions',
          title: 'PIN transactions',
          desc: 'View Black PIN transactions.',
        },
      )

      return menus
    },
  },
}
</script>
